/**componente login**/
.container.login{
    padding: 100px 300px 100px 300px;
    height: 100vh;
    align-self: center;
}

.login .card-header{
    border: none;
    text-align: center;
}

.login .card-footer{
    border: none;
}

.login .wrapping_footer {
    text-align: end;
}

.login button{
    border: 1px solid #000000;
}

.login button:hover{
    background: #000000;
    color: #ffffff;
}

/**Componente AdminAPP**/
.admin-app{
    background: white;
    padding: 35px;
}

.admin-app .nav-item{
    width: 33%;
    text-align: center;
    cursor: pointer;
}

.admin-app h4{
    text-align: center;
    margin-bottom: 16px;
}

.wrapper-cargando{
    padding: 30px;
    width: 100%;
}

.admin-app .wrapper-item {
    display: flex;
    margin-bottom: 16px;
}

.admin-app .wrapper-item img{
    width: 48px;
    height: 48px;
    cursor: pointer;
}

.admin-app .wrapper-item .wrapper-titulo h4{
    margin-bottom: 0;
}

.admin-app .wrapper-item .wrapper-titulo{
    width: 100%;
    align-self: center;
}

/**Componente home**/
.wrapper-habilitar .col-md-12{
    padding: 50px;
}

.wrapper-habilitar .wrapper-titulo{
    margin-bottom: 32px;
}

.wrapper-habilitar h4,
.wrapper-habilitar label{
    margin-bottom: 0;
    align-self: center;
}

.wrapper-habilitar{
    align-self: center;
    margin-bottom: 16px;
}

.wrapper-habilitar .estado img{
    width: 32px;
    height: 32px;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
}

.wrapper-habilitar .wrapper-image img{
    width: 100%;
    border-radius: 8px;
}

/**Componente reservas**/
.wrapper-reservas{
    margin-top: 32px;
    width: 100%!important;
    margin-left: 0!important;
    background-color: white!important;
}

/**Componente Fechas**/
.wrapper-fechas {
    margin-top: 32px;
    width: 100%!important;
    margin-left: 0!important;
    background-color: white!important;
}

.wrapper-fechas label{
    margin-right: 20px;
}

.wrapper-fechas input{
    padding: 10px;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    margin-right: 20px;
}

.wrapper-fechas button{
    border: 1px solid #eee;
    background: #eee;
    color: black;
}

.wrapper-fechas button:hover{
    background: white;
}

.wrapper-fechas .wrapper-todo,
.wrapper-fechas .wrapper-hora{
    display: flex;
    align-self: center;
}

.wrapper-fechas .col-md-6{
    align-self: center;
}

.wrapper-fechas .wrapper-todo{
    margin-left: 20px;
}

.wrapper-fechas .wrapper-todo img,
.wrapper-fechas .wrapper-hora img{
    width: 32px;
    height: 32px;
    margin-right: 20px;
    cursor: pointer;
}

.wrapper-fechas .wrapper-todo p,
.wrapper-fechas .wrapper-hora p{
    margin-bottom: 0;
    align-self: center;
}

.wrapper-fechas table img{
    width: 32px;
    height: 32px;
    cursor: pointer;
}




@media (max-width: 1199px){/**992px -1199px**/
    .container.login {
        padding: 100px 200px;
    }    
}

@media (max-width: 991px){/**768 - 991px**/
    .container.login {
        padding: 100px 100px;
    }      
}

@media (max-width: 767px){ /**576px - 767px**/
    .container.login {
        padding: 100px 50px;
    } 
}

@media (max-width: 575px) { /**0 - 575px**/
    .container.login {
        padding: 100px 25px;
    } 
}